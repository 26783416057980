<template>
  <div class="admin-container">
    <v-row class="justify-space-between">
      <v-col cols="12" md="4">
        <h1>Správa staveb</h1>
      </v-col>
      <v-col align-self="center">
        <v-row class="justify-md-end">
          <v-col cols="12" md="4" class="pa-0 mr-2">
            <v-text-field
              height="32"
              class="pa-0 mt-0"
              v-model="search"
              :label="'Vyhledat ..'"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            align-self="center"
            md="2"
            class="pa-0 flex-0 mr-3 mt-8 mt-md-0"
          >
            <v-btn
              x-small
              tile
              color="secondary"
              height="32"
              @click="handleSearch"
            >
              <v-icon small>mdi-magnify</v-icon>
              Hledat</v-btn
            >
          </v-col>
          <v-col
            align-self="center"
            md="2"
            class="pa-0 flex-0 mr-3 mt-8 mt-md-0"
          >
            <v-btn
              x-small
              tile
              color="secondary"
              height="32"
              @click="dialog = true"
              >Filtrace</v-btn
            >
          </v-col>
          <v-col align-self="center" md="2" class="pa-0 flex-0 mt-8 mt-md-0">
            <v-btn
              x-small
              tile
              color="secondary"
              height="32"
              @click="$router.push({ name: 'BuildingsNew' })"
              ><v-icon small>mdi-plus</v-icon> Nová stavba</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-12">
      <v-col v-for="building in data" :key="building.id" cols="12" md="4">
        <v-card class="elevation-1 pa-8 pa-0" tile>
          <v-card-title style="font-size: 16px"
            >{{ building.name }}
          </v-card-title>
          <v-card-subtitle
            >{{ building.city }} |
            {{ building.completion_date }}
            <div class="d-block">
              {{ building.project_manager.full_name }}
            </div>
          </v-card-subtitle>
          <v-card-actions style="margin-top: -26px">
            <v-row>
              <v-col class="text-right">
                <v-btn
                  x-small
                  height="32"
                  tile
                  color="primary"
                  @click="
                    $router.push({
                      name: 'BuildingsDetail',
                      params: { id: building.id },
                    })
                  "
                  >Upravit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" :max-width="450" persistent class="rounded-0">
        <v-card>
          <v-card-title class="headline"> FILTRACE </v-card-title>

          <v-card-text>
            <v-row>
              <v-col> MĚSTO </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-list flat max-height="200">
                  <v-list-item-group
                    v-model="selectedCities"
                    multiple
                    color="primary"
                  >
                    <v-list-item v-for="(item, i) in cities" :key="i">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.city"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="secondary"
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
            <v-row>
              <v-col>DATUM</v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  ref="menuDateFrom"
                  v-model="menuDateFrom"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFrom"
                      label="Od"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFrom"
                    :active-picker.sync="activePickerFrom"
                    min="1950-01-01"
                    @change="saveFrom"
                    :max="
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() + 10)
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    type="month"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  ref="menuDateTo"
                  v-model="menuDateTo"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateTo"
                      label="Do"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateTo"
                    :active-picker.sync="activePickerTo"
                    :max="
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() + 10)
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="1950-01-01"
                    @change="saveTo"
                    type="month"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col class="text-center">
                <v-btn
                  color="primary"
                  small
                  tile
                  width="100%"
                  @click="queryFilter"
                  >Filtrovat</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn
                  small
                  text
                  @click="
                    (dialog = false),
                      (dateFrom = null),
                      (dateTo = null),
                      (selectedCities = [])
                  "
                  width="100%"
                  >Zrušit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Users from '../api/userService';
import bus from '../plugins/bus';
import authHandler from '../api/authHandler';
import userService from '../api/userService';
import buildingService from '../api/buildingService';
import permissionDenied from '../api/permissionDenied';

export default {
  async created() {
    permissionDenied(
      this.user,
      this.$router,
      'SUPERUSER',
      'PROJECT_MANAGER',
      'MAIN_SUPERUSER',
      'MAIN_PROJECT_MANAGER'
    );
    await this.index();
    await this.citiesConfigFilter();
  },
  data() {
    return {
      selectedCities: [],
      data: [],
      page: 1,
      search: '',
      dialog: false,
      cities: [],
      selectedRoles: [],
      dateFrom: null,
      menuDateFrom: false,
      activePickerFrom: null,
      dateTo: null,
      menuDateTo: false,
      activePickerTo: null,
    };
  },
  methods: {
    async citiesConfigFilter() {
      try {
        const cities = await buildingService.indexFilterConfig();
        this.cities = cities;
      } catch (error) {
        console.error(error);
      }
    },
    async queryFilter() {
      try {
        let filterCities = null;
        if (this.selectedCities) {
          let selectedCities = [];
          for (const idx of this.selectedCities) {
            selectedCities.push(this.cities[idx].city);
          }
          filterCities = selectedCities.join(',');
        }
        let dateFrom = null;
        if (this.dateFrom) {
          dateFrom = this.dateFrom;
        }
        let dateTo = null;
        if (this.dateTo) {
          dateTo = this.dateTo;
        }
        await this.index(null, filterCities, dateFrom, dateTo);
      } catch (error) {
        console.error(error);
      }
    },
    async filterRoles() {
      try {
        this.dialog = false;
        let roles = [];
        for (const idx of this.selectedRoles) {
          roles.push(this.roles[idx].name);
        }
        await this.index(null, roles.join(','));
      } catch (error) {
        console.error(error);
      }
    },
    async handleSearch() {
      console.debug('searching ....');
      await this.index(this.search);
    },
    async index(
      search = null,
      filter = null,
      filterFrom = null,
      filterTo = null
    ) {
      try {
        bus.$emit('processing');
        const data = await buildingService.index(
          this.page,
          this.perPage,
          this.sortBy,
          this.sortDesc,
          this.search,
          filter,
          filterFrom,
          filterTo
        );
        this.data = data;
        // this.totalItems = total;
        bus.$emit('processing', false);
      } catch (error) {
        // Handle error
        if (!authHandler(error)) this.$router.push({ name: 'Login' });
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
          this.$router.push({ name: 'Dashboard' });
        }
      }
    },
    handleClick(i) {
      this.$router.push({ name: 'UserDetail', params: { userId: i.id } });
    },
    async updateFooteOptions(e) {
      console.log('Pagination changed', e);
      this.sortBy = e.sortBy[0] || 'id';
      this.sortDesc = e.sortDesc[0] || false;
      // if (e.page != this.page || this.perPage != e.itemsPerPage) {
      this.page = e.page;
      this.perPage = e.itemsPerPage;
      this.$router
        .replace({
          name: this.$router.name,
          query: {
            page: this.page,
            limit: this.limit,
          },
        })
        .catch((err) => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes(
              'Avoided redundant navigation to current location'
            )
          ) {
            console.log(err);
          }
        });
      // }
      return;
    },
    saveFrom(date) {
      this.$refs.menuDateFrom.save(date);
    },
    saveTo(date) {
      this.$refs.menuDateTo.save(date);
    },
  },
  watch: {
    menuDateFrom(val) {
      val && setTimeout(() => (this.activePickerFrom = 'YEAR'));
    },
    menuDateTo(val) {
      val && setTimeout(() => (this.activePickerTo = 'YEAR'));
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>
